var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.$apollo.loading && !_vm.activeBankingProduct)?_c('page-not-found'):_c('div',[_c('div',{staticClass:"banner-landing"},[_c('div',{staticClass:"container banner-grid"},[_c('div',{staticClass:"banner-col-1 flex flex-ac"},[_c('div',{staticClass:"banner-content"},[(!_vm.$apollo.loading)?[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.activeBankingProduct.heading)}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.activeBankingProduct.subHeading))])]:_vm._e(),_vm._v(" "),_c('sg-button',{staticClass:"btn-cta",attrs:{"color":"orange"},on:{"click":_vm.onOpenFindOutMore}},[_vm._v("\n            "+_vm._s(_vm.$t("Find out more"))+"\n          ")])],2)]),_vm._v(" "),_c('div',{staticClass:"banner-col-2 flex flex-jc flex-ac"},[_c('div',{staticClass:"banner-promo"},[(_vm.contentPage)?_c('img',{staticClass:"img-resp",attrs:{"src":_vm.contentPage.originalBannerImageUrl,"alt":"Bank ATM"}}):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"oval-lg"},[_c('img',{staticClass:"img-resp",attrs:{"src":require('../../../images/oval-lg.svg'),"alt":"background image","aria-hidden":"true","role":"presentation"}})]),_vm._v(" "),_c('div',{staticClass:"oval"},[_c('img',{staticClass:"img-resp",attrs:{"src":require('../../../images/oval.svg'),"alt":"background image","aria-hidden":"true","role":"presentation"}})])]),_vm._v(" "),_c('section',{staticClass:"below-banner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-jc"},[_c('div',{staticClass:"partner-logo mb-2"},[_c('img',{staticClass:"img-resp",attrs:{"src":require('../../../images/Commbank-logo.png'),"alt":"Commbank logo"}})])]),_vm._v(" "),_c('h2',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$t("Say hello to the Commonwealth Bank of Australia"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-jc mb-1"},[_c('div',{staticClass:"w-80 text-center"},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                "There’s a lot to look forward to when you move to Australia to study. Not only can you enjoy world class educational facilities, but every day can be an adventure. Whether you’re surfing at a sunny beach, tasting fresh seafood or discovering Australian wildlife there’s always something to look forward to. We want to make your move to the sunny side even smoother by introducing you to the Commonwealth Bank of Australia. They’re known to Aussies as CommBank.",
              ))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"features-wrapper supplier-wrapper"},[_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/one.svg'),"alt":"best icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Australia's largest bank"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "CommBank is Australia’s largest bank. They support more people moving to Australia than any other bank.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/mobile-banking.svg'),"alt":"icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Bank on the go"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Enjoy convenient banking on the go with the CommBank app.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/network.svg'),"alt":"network icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Extensive network"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "An extensive Branch and ATM network across Australia, including on some student campuses.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/support.svg'),"alt":"support icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Language support"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "You'll find staff at many CommBank branches bilingual plus ATMs are language enabled.",
                ))+"\n            ")])])])])])]),_vm._v(" "),_c('section',{staticClass:"blue-line blue-bg"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-heading"},[_c('h2',{staticClass:"text-center mb-0"},[_vm._v("\n          "+_vm._s(_vm.$t("How it works"))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"features-wrapper how-wrapper"},[_c('div',{staticClass:"feature"},[_c('div',{staticClass:"feature-icon"},[_c('img',{attrs:{"src":require('../../../images/h1.svg'),"alt":"accommodation icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Find out more"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Click on the link to be redirected to CommBank's moving to Australia website.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"feature-icon"},[_c('img',{attrs:{"src":require('../../../images/price-guarantee-icon.svg'),"alt":"icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Explore products & services"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "With a variety of products and services, you can get your banking sorted out in no time.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"feature-icon"},[_c('img',{attrs:{"src":require('../../../images/id.svg'),"alt":"identity icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Follow Simple Steps"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Follow the simple instructions on the CommBank website to fulfil all your banking needs.",
                ))+"\n            ")])])])])])]),_vm._v(" "),_c('section',{staticClass:"blue-line"},[_c('div',{staticClass:"container flex flex-jc flex-ac"},[_c('sg-button',{staticClass:"btn-cta",attrs:{"color":"orange"},on:{"click":_vm.onOpenFindOutMore}},[_vm._v("\n        "+_vm._s(_vm.$t("Find out more"))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"container"},[_c('p',{staticClass:"small text-center mt-3"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Things you should know"))+":")]),_vm._v(" "),_c('br'),_vm._v("\n        "+_vm._s(_vm.$t(
            "Commonwealth Bank of Australia ABN 48 123 123 124 is a company incorporated in Australia with limited liability and subject to Australian regulation (CommBank).",
          ))+"\n      ")])])]),_vm._v(" "),(_vm.showFindOutMore)?_c('find-out-more-dialog',{on:{"close":_vm.onCloseFindOutMore}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }