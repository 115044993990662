import Vue from "vue";
import AsyncStatus from "../../../utils/async";
import visitBanking from "../../../api/mutations/visitBanking";
export default Vue.extend({
    data: function () {
        return {
            honeyPotValue: "",
            referralUrl: undefined,
            form: {
                firstName: "",
                lastName: "",
                emailAddress: "",
                agent: undefined,
            },
            status: new AsyncStatus(),
        };
    },
    methods: {
        onAgentChange: function (isChecked) {
            if (isChecked) {
                this.form.agent = {
                    code: "",
                };
                return;
            }
            // Causes an error. See:
            // https://github.com/logaretm/vee-validate/issues/2109#issuecomment-498931614
            this.form.agent = undefined;
        },
        onSubmit: function () {
            var _this = this;
            this.status
                .run(this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return Promise.resolve();
                }
                return _this.$apollo
                    .mutate(visitBanking({
                    firstName: _this.form.firstName,
                    lastName: _this.form.lastName,
                    emailAddress: _this.form.emailAddress,
                    agentCode: _this.form.agent ? _this.form.agent.code : undefined,
                }))
                    .then(function (_a) {
                    var data = _a.data;
                    if (!data) {
                        throw new Error("There was an error visiting the bank");
                    }
                    _this.referralUrl = data.visitBanking.referralUrl;
                });
            }))
                .catch(function () { return undefined; });
        },
        onVisitClick: function () {
            if (!this.referralUrl) {
                throw new Error("Invalid state");
            }
            window.open(this.referralUrl);
            this.$emit("close");
        },
    },
});
