var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { createMetaDescription } from "../../../utils/meta";
import FindOutMoreDialog from "./FindOutMoreDialog.vue";
export default Vue.extend({
    components: {
        FindOutMoreDialog: FindOutMoreDialog,
    },
    data: function () {
        return {
            showFindOutMore: false,
        };
    },
    apollo: {
        activeBankingProduct: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        activeBankingProduct {\n          heading\n          subHeading\n        }\n      }\n    "], ["\n      {\n        activeBankingProduct {\n          heading\n          subHeading\n        }\n      }\n    "]))),
        contentPage: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        contentPage(key: \"banking\") {\n          metaTitle\n          metaDescription\n          originalBannerImageUrl\n        }\n      }\n    "], ["\n      {\n        contentPage(key: \"banking\") {\n          metaTitle\n          metaDescription\n          originalBannerImageUrl\n        }\n      }\n    "]))),
    },
    methods: {
        onCloseFindOutMore: function () {
            this.showFindOutMore = false;
        },
        onOpenFindOutMore: function () {
            this.showFindOutMore = true;
        },
    },
    metaInfo: function () {
        if (!this.contentPage) {
            return {};
        }
        return {
            title: this.contentPage.metaTitle,
            meta: [createMetaDescription(this.contentPage.metaDescription)],
        };
    },
});
var templateObject_1, templateObject_2;
